import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SettingsSidebar from './SettingsSidebar'; // Sidebar component for settings
import Header from './Header'; // Import Header component
import './SettingsPage.css';

const SettingsPage = ({ onSelectPrompt }) => {
    const [prompts, setPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [activeTab, setActiveTab] = useState('Main');
    const [showFullText, setShowFullText] = useState(false);
    const [newPromptName, setNewPromptName] = useState('');
    const [newPromptText, setNewPromptText] = useState('');
    const [llmProvider, setLlmProvider] = useState('');
    const [model, setModel] = useState('');
    const [temperature, setTemperature] = useState('');
    const [topP, setTopP] = useState('');

    useEffect(() => {
        if (activeTab === 'Prompts') {
            axios.get('https://mdlpearl.ddns.net/api/prompts')
                .then(response => {
                    setPrompts(response.data);
                })
                .catch(error => console.error('Error fetching prompts:', error));
        }
    }, [activeTab]);

    const handleSelectPrompt = (event) => {
        const promptId = event.target.value;
        const prompt = prompts.find(p => p.id.toString() === promptId);
        setSelectedPrompt(prompt || null);
        setNewPromptName(prompt ? prompt.prompt_name : '');
        setNewPromptText(prompt ? prompt.prompt_text : '');
    };

    const handleUpdatePrompt = () => {
        if (!selectedPrompt) {
            alert('Please select a prompt to update.');
            return;
        }

        axios.put(`https://mdlpearl.ddns.net/api/prompts/update/${selectedPrompt.id}`, {
            name: newPromptName,
            text: newPromptText,
        }).then(response => {
            const updatedPrompts = prompts.map(p => 
                p.id === response.data.id ? response.data : p
            );
            setPrompts(updatedPrompts);
        }).catch(error => console.error('Error updating prompt:', error));
    };

    const handleCreatePrompt = () => {
        axios.post('https://mdlpearl.ddns.net/api/prompts/create', {
            name: newPromptName,
            text: newPromptText,
        }).then(response => {
            setPrompts([...prompts, response.data]);
            setNewPromptName(''); // Clear fields after creation
            setNewPromptText('');
        }).catch(error => console.error('Error creating new prompt:', error));
    };

    return (
        <div>
            <Header /> {/* Add Header component */}
            <div className="settings-page-container">
                <SettingsSidebar 
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <div className="content">
                    <h2>Settings</h2>
                    <div className="tab-content">
                        {activeTab === 'Main' && (
                            <MainContent
                                llmProvider={llmProvider}
                                setLlmProvider={setLlmProvider}
                                model={model}
                                setModel={setModel}
                                temperature={temperature}
                                setTemperature={setTemperature}
                                topP={topP}
                                setTopP={setTopP}
                            />
                        )}
                        {activeTab === 'Prompts' && (
                            <div>
                                <h3>Select a System Prompt</h3>
                                <select onChange={handleSelectPrompt} value={selectedPrompt?.id || ''}>
                                    <option value="">Select a prompt</option>
                                    {prompts.map(prompt => (
                                        <option key={prompt.id} value={prompt.id}>
                                            {prompt.prompt_name}
                                        </option>
                                    ))}
                                </select>
                                
                                <div style={{ marginTop: '20px' }} className="prompt-details">
                                    <h3>Prompt Details</h3>
                                    <label>
                                        Name:
                                        <input 
                                            type="text" 
                                            value={newPromptName}
                                            onChange={(e) => setNewPromptName(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        Text:
                                        <textarea 
                                            value={newPromptText}
                                            onChange={(e) => setNewPromptText(e.target.value)}
                                        />
                                    </label>
                                    <div className="prompt-buttons">
                                        <button onClick={handleUpdatePrompt}>Update Prompt</button>
                                        <button onClick={handleCreatePrompt}>Save as New Prompt</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'Data Upload' && (
                            <FileUploadHandler />
                        )}
                        {activeTab === 'Other Settings' && <OtherSettingsContent />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainContent = ({ llmProvider, setLlmProvider, model, setModel, temperature, setTemperature, topP, setTopP }) => (
    <div>
        <h3>Main Settings</h3>
        <p>Configure your main settings here.</p>
        <div>
            <label>
                Select LLM Provider:
                <select value={llmProvider} onChange={(e) => setLlmProvider(e.target.value)}>
                    <option value="">Select a provider</option>
                    <option value="OpenAI">OpenAI</option>
                    <option value="Anthropic">Anthropic</option>
                    <option value="OpenSource">OpenSource</option>
                </select>
            </label>
        </div>
        <div>
            <label>
                Select Model:
                <select value={model} onChange={(e) => setModel(e.target.value)}>
                    <option value="">Select a model</option>
                    {llmProvider === 'OpenAI' && (
                      <>
                        <option value="gpt-4o-mini">GPT-4o-mini</option>
                        <option value="gpt-4">GPT-4</option>
                      </>
                    )}
                    {llmProvider === 'Anthropic' && (
                      <>
                        <option value="claude-haiku">Claude Haiku</option>
                        <option value="claude-sonnet">Claude Sonnet</option>
                      </>
                    )}
                    {llmProvider === 'OpenSource' && (
                      <>
                        <option value="llama3.1">Llama3.1</option>
                        <option value="mistral">Mistral</option>
                      </>
                    )}
                </select>
            </label>
        </div>
        <div className="model-configuration">
            <h4>Model Configuration</h4>
            <div>
                <label>
                    Temperature:
                    <input
                        type="text"
                        value={temperature}
                        onChange={(e) => setTemperature(e.target.value)}
                    />
                </label>
            </div>
            <div>
                <label>
                    Top-P:
                    <input
                        type="text"
                        value={topP}
                        onChange={(e) => setTopP(e.target.value)}
                    />
                </label>
            </div>
        </div>
    </div>
);

const OtherSettingsContent = () => (
    <div>
        <h3>Other Settings</h3>
        <p>Configure other application settings here.</p>
    </div>
);

const FileUploadHandler = () => {
    const [files, setFiles] = useState([]);
    const [directoryName, setDirectoryName] = useState('');

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleDirectoryChange = (event) => {
        setDirectoryName(event.target.value);
    };

    const handleFileUpload = async () => {
        if (files.length === 0 || !directoryName) {
            alert('Please enter a directory name and select files to upload.');
            return;
        }
        const formData = new FormData();
        formData.append('directory', directoryName);
        Array.from(files).forEach(file => formData.append('uploaded_files', file));

        try {
            const response = await fetch('https://mdlpearl.ddns.net/api/upload-and-save', { // Updated API endpoint
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('File upload error:', error);
        }
    };

    return (
        <div>
            <h3>Upload Files</h3>
            <input
                type="text"
                placeholder="Enter directory name"
                value={directoryName}
                onChange={handleDirectoryChange}
            />
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>
        </div>
    );
};

export default SettingsPage;
