import React, { useState } from 'react';

const ColumnSelectionModal = ({
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
  visibleColumns,
  setVisibleColumns,
  systemPrompts,
  selectedSystemPrompt,
  setSelectedSystemPrompt,
  columns, // Add columns prop
  onToggleColumn // Add onToggleColumn prop
}) => {
  const [showFullText, setShowFullText] = useState(false);

  if (!isOpen) return null;

  const handlePromptSelection = (event) => {
    const promptId = event.target.value;
    const prompt = systemPrompts.find(p => p.id.toString() === promptId);
    setSelectedSystemPrompt(prompt?.prompt_text || '');
    setShowFullText(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Review Settings</h2>

        <div className="tabs">
          {['Select Columns', 'Summary Prompt'].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="tab-content">
          {activeTab === 'Select Columns' && (
            <div>
              <h3>Select Columns to Display</h3>
              {columns.map(col => (
                <div key={col}>
                  <label>
                    <input
                      type="checkbox"
                      checked={visibleColumns.includes(col)}
                      onChange={() => onToggleColumn(col)}
                    />
                    {col}
                  </label>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'Summary Prompt' && (
            <div>
              <h3>Select a System Prompt</h3>
              <select onChange={handlePromptSelection} value={selectedSystemPrompt}>
                <option value="">Select a prompt</option>
                {systemPrompts.map(prompt => (
                  <option key={prompt.id} value={prompt.id}>
                    {prompt.prompt_name}
                  </option>
                ))}
              </select>
              {selectedSystemPrompt && (
                <div>
                  <h3>Selected Prompt Details</h3>
                  <div style={{ maxWidth: '400px', display: 'flex', alignItems: 'center' }}>
                    <span>
                      {showFullText ? selectedSystemPrompt : `${selectedSystemPrompt.substring(0, 100)}...`}
                    </span>
                    {selectedSystemPrompt.length > 100 && (
                      <button onClick={() => setShowFullText(!showFullText)}>
                        {showFullText ? 'Show Less' : 'Show More'}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ColumnSelectionModal;