
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DataReviewSidebar.css'; // Ensure you have appropriate styling

const DataReviewSidebar = ({
    collections,
    selectedCollection,
    setSelectedCollection,
    knGraphOption,
    setKnGraphOption,
    directories,
    selectedDirectory,
    handleDirectorySelection,
    directoryFiles,
    toggleFileInList,
    files,
    clearSearchResults, // Function passed from DataReview.js to clear search results
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [fileCount, setFileCount] = useState(0);
    const [query, setQuery] = useState('');

    const handleSearch = async () => {
        if (searchQuery) {
            try {
                const response = await axios.get(`https://mdlpearl.ddns.net/api/search_files?searchTerm=${encodeURIComponent(searchQuery)}`);
                setSearchResults(response.data.results);
                setFileCount(response.data.fileCount || 0);
                setQuery(response.data.query || 'No query generated');
            } catch (error) {
                console.error('Error searching files:', error);
            }
        } else {
            setSearchResults([]);
            setFileCount(0);
            setQuery('No query generated');
        }
    };

    // Clear search results when the clearSearchResults prop is triggered
    useEffect(() => {
        if (clearSearchResults) {
            clearSearchResults(() => setSearchResults([]));
        }
    }, [clearSearchResults]);

    return (
        <div className="data-review-sidebar">
            <h2>Search Files</h2>
            <input
                type="text"
                placeholder="Search files"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button onClick={handleSearch}>Search</button>

            {fileCount > 0 && (
                <div>
                    <h4>Number of Matching Files:</h4>
                    <span>{fileCount}</span>
                </div>
            )}

            {query && query !== "No query generated" && (
                <div>
                    <h4>Generated Query:</h4>
                    <pre>{query}</pre>
                </div>
            )}

            {searchResults.length > 0 && (
                <div>
                    <button onClick={() => toggleFileInList(searchResults, true)}>Select All</button>
                    <button onClick={() => {
                        setSearchQuery('');
                        setSearchResults([]);
                        setFileCount(0); // Reset file count
                        setQuery('No query generated'); // Reset generated query
                    }}>Clear Search</button>
                    <h3>Search Results</h3>
                    <ul>
                        {searchResults.map((file, index) => (
                            <li key={index}>
                                <button onClick={() => toggleFileInList(file)}>
                                    {files.find(f => f.id === file.id) ? 'Remove' : 'Add'} {file.file_name || 'Unnamed File'}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <h2>Collection Selection</h2>
            <select
                className="uniform-input"
                value={selectedCollection}
                onChange={(e) => setSelectedCollection(e.target.value)}
            >
                <option value="">Select a Collection</option>
                {collections.sort((a, b) => a.localeCompare(b)).map((collection, index) => (
                    <option key={index} value={collection}>
                        {collection}
                    </option>
                ))}
            </select>

            <h3>Graph Option</h3>
            <select
                className="uniform-input"
                value={knGraphOption}
                onChange={(e) => setKnGraphOption(e.target.value)}
            >
                <option value="kn_graph">KN Graph</option>
                <option value="kn_graph_full">KN Graph Full</option>
            </select>

            {/* <h3>Directories</h3>
            <select
                className="uniform-input"
                value={selectedDirectory}
                onChange={(e) => handleDirectorySelection(e.target.value)}
            >
                <option value="">Select a Directory</option>
                {directories.map((directory, index) => (
                    <option key={index} value={directory}>
                        {directory}
                    </option>
                ))}
            </select>

            <h3>Files in Directory</h3>
            <ul>
                {directoryFiles.map((file, index) => (
                    <li key={index}>
                        <button onClick={() => toggleFileInList(file)}>
                            {files.find(f => f.id === file.id) ? 'Remove' : 'Add'} {file.file_name || 'Unnamed File'}
                        </button>
                    </li>
                ))}
            </ul> */}

            <h3>Selected Files</h3>
            <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        {file.file_name || 'Unnamed File'}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DataReviewSidebar;